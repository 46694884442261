/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";
import { Business as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query BusinessHereQuery {
    craft {
      entry(section: "doingBusinessHere") {
        title
        url
        slug
        ... on Craft_doingBusinessHere_doingBusinessHere_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroLabel
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #carousel
          commonCarousel {
            ... on Craft_commonCarousel_carsouelblock_BlockType {
              uid
              carouselTitle
              carouselDescription
              carouselLink {
                url
              }
              carouselImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          #blocks
          commonImageCopyBlock {
            ... on Craft_commonImageCopyBlock_imageCopyBlock_BlockType {
              uid
              imageCopyBlockTitle
              imageCopyBlockDescription
              imageCopyBlockImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              imageCopyBlockCTA {
                text
                url
              }
              imageCopyBlockPDF {
                url
              }
            }
          }
          #card grid
          commonHeader
          commonDescription
          commonIconTextGroup {
            ... on Craft_commonIconTextGroup_iconTextGroupBlock_BlockType {
              iconTextGroupAnchor
              iconTextGroupBlockIcon {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              iconTextGroupBlockTitle
              iconTextGroupDescription
              iconTextGroupBlockLink {
                text
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `query BusinessHereQuery {
  craft {
    entry(section: "doingBusinessHere") {
      title
      url
      slug
      ... on Craft_doingBusinessHere_doingBusinessHere_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroLabel
        heroTitle
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #carousel
        commonCarousel {
          ... on Craft_commonCarousel_carsouelblock_BlockType {
            uid
            carouselTitle
            carouselDescription
            carouselLink {
              url
            }
            carouselImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        #blocks
        commonImageCopyBlock {
          ... on Craft_commonImageCopyBlock_imageCopyBlock_BlockType {
            uid
            imageCopyBlockTitle
            imageCopyBlockDescription
            imageCopyBlockImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            imageCopyBlockCTA {
              text
              url
            }
            imageCopyBlockPDF {
              url
            }
          }
        }
        #card grid
        commonHeader
        commonDescription
        commonIconTextGroup {
          ... on Craft_commonIconTextGroup_iconTextGroupBlock_BlockType {
            iconTextGroupAnchor
            iconTextGroupBlockIcon {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            iconTextGroupBlockTitle
            iconTextGroupDescription
            iconTextGroupBlockLink {
              text
              url
            }
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroLabel,
    heroTitle,
    heroImage,
    // carousel
    commonCarousel,
    // intro
    commonImageCopyBlock,
    // card grid
    commonHeader,
    commonDescription,
    commonIconTextGroup,
  } = craft.entry;

  const resolveBlocks = blocks =>
    blocks.map(b => {
      return {
        uid: b.uid,
        heading: b.imageCopyBlockTitle,
        copy: b.imageCopyBlockDescription,
        image: resolveImage(b.imageCopyBlockImage),
        link: {
          text: b.imageCopyBlockCTA.text,
          url: b.imageCopyBlockCTA.url,
        },
        pdf: b.imageCopyBlockPDF[0]?.url,
      };
    });

  const resolveCards = cards =>
    cards.map(c => {
      return {
        anchor: c.iconTextGroupAnchor,
        image: resolveImage(c.iconTextGroupBlockIcon),
        heading: c.iconTextGroupBlockTitle,
        copy: c.iconTextGroupDescription,
        link: {
          text: c.iconTextGroupBlockLink.text,
          url: c.iconTextGroupBlockLink.url,
        },
      };
    });

  const resolveCarousel = slides =>
    slides.map(s => {
      return {
        uid: s.uid,
        title: s.carouselTitle,
        copy: s.carouselDescription,
        link: {
          url: s.carouselLink.url,
        },
        image: resolveImage(s.carouselImage),
      };
    });

  return {
    title,
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    carousel: resolveCarousel(commonCarousel),
    intro: {
      blocks: resolveBlocks(commonImageCopyBlock),
    },
    cardGrid: {
      heading: commonHeader,
      copy: commonDescription,
      cards: resolveCards(commonIconTextGroup),
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
