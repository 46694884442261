/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useAppState } from "@state/state";
// import { use404PageData } from "@staticQueries";
import * as DynamicQueries from "@dynamicQueries";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HomeImageGrid } from "@organisms";
import { PageHero } from "@molecules";
import { Container, Text, Button } from "@atoms";
import { useErrorData } from "@staticQueries";

const NotFoundPage = ({ pageContext, location, ...rest }) => {
  const [{ layout }, dispatch] = useAppState();
  const meta = { title: "404" };
  const { hero, body, ctas } = useErrorData();

  useEffect(() => {
    // set preview mode if preview data is detected
    const {
      "x-craft-live-preview": xCraftLivePreview,
      "x-craft-preview": xCraftPreview,
    } = qs.parse(location.search);
    if (xCraftLivePreview || xCraftPreview) {
      dispatch({ type: "setPreviewMode", previewMode: "loading" });
    }
  }, []);

  if (layout.previewMode) {
    // Use the appropriate page query based on the url
    // Note we need to manually set a `pageContext` prop and supply
    // it with a slug which we're grabbing from the last segment of the url.
    // This is used in the preview query to fetch the appropriate entry
    const split = location.pathname.split("/");
    const firstSegment = split[1];
    const lastSegment = split[split.length - 1];
    const props = { ...rest, location, pageContext: { slug: lastSegment } };

    // set up queries here for preview
    if (split.length) {
      switch (firstSegment) {
        case "blog":
          return <DynamicQueries.BlogQuery {...props} />;
        case "events":
          return <DynamicQueries.EventsQuery {...props} />;
        case "major-initiatives":
          return <DynamicQueries.InitiativesQuery {...props} />;
        case "tools-resources":
          return <DynamicQueries.ToolsResourcesQuery {...props} />;
        default:
          return <DynamicQueries.LandingPageQuery {...props} />;
      }
    }
    return <DynamicQueries.LandingPageQuery {...props} />;
  }

  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <div className="flex flex-col items-center text-center justify-center flex-grow py-24 md:py-48">
        <Container variant="xl" className="space-y-4">
          <Text variant="h3" className="text-blue uppercase">
            {body.heading}
          </Text>
          <Text variant="xs" className="text-gray">
            {body.copy}
          </Text>
          <Button>{body.cta}</Button>
        </Container>
      </div>
      <HomeImageGrid {...ctas} />
    </PageContainer>
  );
};

export default NotFoundPage;
